import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    lastRequest: {
        timestamp: null,
        request: {},
    },
    lastResponse: {
        timestamp: null,
        response: {},
    },
    lastError: {
        timestamp: null,
        error: {},
    },
};

const apiSlice = createSlice({
    name: "api",
    initialState: INITIAL_STATE,
    reducers: {
        setRequest(state, action) {
            return {
                ...state,
                lastRequest: {
                    timestamp: Date.now(),
                    request: action.payload,
                },
            };
        },
        setResponse(state, action) {
            return {
                ...state,
                lastResponse: {
                    timestamp: Date.now(),
                    reponse: action.payload,
                },
            };
        },
        setError(state, action) {
            return {
                ...state,
                lastError: {
                    timestamp: Date.now(),
                    error: action.payload,
                },
            };
        },
    },
});

export const apiSliceActions = apiSlice.actions;

export default apiSlice;
