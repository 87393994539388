import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import store from "../store/store";
import { apiSliceActions } from "../store/apiSlice/apiSlice";

const authAxios = axios.create({
    baseURL: process.env.REACT_APP_ECS_API_URL,
});

// this runs before api call
authAxios.interceptors.request.use((request) => {
    // Checking token is expired
    const token = request.headers.Authorization;
    let tokenDuration = new Date().getTime();
    if (token !== undefined) {
        tokenDuration = new Date(jwt_decode(token.split(" ")[1]).exp * 1000).getTime();
    }
    if (new Date().getTime() >= tokenDuration) {
        Cookies.remove("Session");
        window.location = process.env.REACT_APP_PORTAL_URL + "/#/logout";
    }

    // Stringify then parse gets rid of non-serializable content
    let r = {
        ...request,
        headers: {
            ...request.headers,
            Authorization: "Redacted",
        },
    };
    store.dispatch(apiSliceActions.setRequest(JSON.parse(JSON.stringify(r))));
    return request;
});

// Runs after api response
authAxios.interceptors.response.use(
    (response) => {
        // We don't need to save/log the entire response content
        let r = {
            ...response,
            data: { ...response.data, content: "Not saving this" },
            config: {
                ...response.config,
                headers: {
                    ...response.config.headers,
                    Authorization: "Redacted",
                },
            },
        };

        // Stringify then parse gets rid of non-serializable content
        store.dispatch(apiSliceActions.setResponse(JSON.parse(JSON.stringify(r))));
        return response;
    },
    (error) => {
        // Stringify then parse gets rid of non-serializable content
        store.dispatch(apiSliceActions.setError(JSON.parse(JSON.stringify(error))));
        return error;
    }
);

export default authAxios;
