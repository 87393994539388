import React, { Component } from "react";
import "./css/ErrorBoundaries.css";
import CommonAPI from "../../api/CommonApi";
import Cookies from "js-cookie";
import store from "../../store/store";

export default class ErrorBoundaries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
        };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });

        const token = Cookies.get("Session");

        const state = store.getState();

        const { selectedCustomer, selectedFacility, selectedEquipment } = state.equipmentsPortfolio;
        const { selectedTab } = state.tabStatus;
        const selectedVoltageType = state.voltageType.selectedVoltageType;
        const { lastError, lastRequest, lastResponse } = state.api;

        const message =
            `${error.toString()}\n` +
            `${info["componentStack"]}\n` +
            `selected customer: ${selectedCustomer?.label}\n` +
            `selected facility: ${selectedFacility?.label}\n` +
            `selected equipment: ${selectedEquipment?.label}\n` +
            `active tab: ${selectedTab}\n` +
            `Voltage type: ${selectedVoltageType.label}\n` +
            `Last API Request ${JSON.stringify(lastRequest)}\n` +
            `Last API Response ${JSON.stringify(lastResponse)}\n` +
            `Last API Error ${JSON.stringify(lastError)}`;

        CommonAPI.reportError(token, message);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div id="error-page" className="top-margin">
                    <div className="content-align">
                        <h3>Something went wrong...</h3>
                    </div>
                    <div className="content-align">
                        <p>
                            This issue has been reported to the Volta Team. Please reload the page to continue using
                            Volta Analytics
                        </p>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}
